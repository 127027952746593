<template>
  <div class="fa-wrap">
    <!--修改密碼 頁面內容-->
    <article class="app-page personal md:max-w-1200 md:mx-auto md:mb-14">
      <!--錯誤訊息-->
      <div class="p-4 md:p-0">
        <h2 class="app-page-tit md:hidden">修改密碼</h2>
        <header class="relative hidden md:block ">
          <h2 class="text-3xl font-bold text-center mb-8">個人資料</h2>
          <p class="text-lg -mt-3 absolute top-1/2 left-0">
            <router-link :to="{ name: 'login' }" class="flex items-center"> <a-icon type="arrow-left" class="mr-2" />回上一頁 </router-link>
          </p>
        </header>
        <section class="forms-basic md:p-4 lg:px-12 md:shadow-pink md:rounded-xl">
          <a-form-model ref="registerForm" :model="form" :rules="rules" class="grid md:grid-cols-2">
            <div class="lg:mx-12 md:m-4">
              <p class="item-title flex justify-between"><span>舊密碼</span></p>
              <a-form-model-item prop="old_password">
                <a-input type="password" placeholder="密碼須為6字以上英數字" v-model="form.old_password" autocomplete="new-password" />
              </a-form-model-item>
            </div>
            <div class="lg:mx-12 md:m-4">
              <p class="item-title flex justify-between"><span>新密碼</span></p>
              <a-form-model-item prop="new_password">
                <a-input type="password" placeholder="密碼須為6字以上英數字" v-model="form.new_password" autocomplete="new-password" />
              </a-form-model-item>
            </div>
            <div class="lg:mx-12 md:m-4">
              <p class="item-title flex justify-between items-baseline"><span>確認新密碼</span></p>
              <a-form-model-item prop="confirmPassword">
                <a-input type="password" placeholder="密碼須為6字以上英數字" v-model="form.confirmPassword" autocomplete="new-password" />
              </a-form-model-item>
            </div>
          </a-form-model>
          <section class="btn-bottom-groups">
            <div class="btn-forms btn-light" @click="handleBack">
              回上一頁
            </div>
            <div class="btn-forms" @click="handleSubmit">
              確定
            </div>
          </section>
        </section>
      </div>
    </article>
    <!--/personal 個人資料頁面內容-->
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { regExpPassword } from '@/utils/regExp.js'

export default {
  name: 'changePassword',
  data() {
    return {
      form: {
        old_password: '',
        new_password: ''
      },
      rules: {
        // old_password: { required:true, message: '請寫入舊密碼', trigger: 'change' },
        old_password: { required: true, validator: (rule, value, callback) => this.validatorOldPassword(value, callback), trigger: 'change' },
        new_password: { required: true, validator: (rule, value, callback) => this.validatorNewPassword(value, callback), trigger: 'change' },
        confirmPassword: { validator: (rule, value, callback) => this.validatorComparePassword(value, callback, '兩者密碼不相同'), trigger: 'change' }
      }
    }
  },
  computed: {
    ...mapGetters(['user'])
  },
  methods: {
    ...mapActions(['changePassword']),
    handleBack() {
      this.$router.go(-1)
    },
    handleChange(column, { file, value }) {
      this.form[column] = {
        file,
        value
      }
      this.$refs.registerForm.validateField(column)
    },
    validatorOldPassword(value, callback) {
      const oldPassword = this.form.old_password
      const newPassword = this.form.new_password
      if (oldPassword == newPassword) {
        callback('新舊密碼不可相同')
        return
      }

      // const reg = regExpPassword
      // if (!reg.test(value)) {
      //   this.isVerifying = false
      //   callback('密碼6-20字, 英文大小寫數字至少1個')
      // } else if (reg.test(value)) this.isVerifying = true
      this.isVerifying = true

      callback()
    },
    validatorNewPassword(value, callback) {
      const oldPassword = this.form.old_password
      const newPassword = this.form.new_password
      if (oldPassword == newPassword) {
        callback('新舊密碼不可相同')
        return
      }

      const reg = regExpPassword
      if (!reg.test(value)) {
        this.isVerifying = false
        callback('密碼6-20字, 英文大小寫數字至少1個')
      } else if (reg.test(value)) this.isVerifying = true

      callback()
    },
    validatorComparePassword(value, callback, errorText) {
      const password = this.form.new_password
      if (value != password) {
        callback(errorText)
      }
      callback()
    },
    async handleSubmit() {
      this.$refs.registerForm.validate(valid => {
        if (!valid) return
        console.log('handleSubmit')
        const formData = new FormData()
        formData.append('old_password', this.form.old_password)
        formData.append('new_password', this.form.new_password)
        if (!formData.entries().next().done) {
          // * api 更變密碼
          this.changePassword(formData)
        }
      })
    }
  },
  async mounted() {}
}
</script>
